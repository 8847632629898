<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Service Report</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="+$route.query.id"
          :variant="openSection ? 'primary' : 'outline-primary'"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle mr-1"
          @click="openSection = !openSection"
        >
          <feather-icon icon="MessageCircleIcon" size="16" />
        </b-button>
        <b-button
          v-b-modal.modal-manage
          v-if="+$route.query.id"
          variant="danger"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="Trash2Icon" size="16" />
        </b-button>

        <Modal modalId="modal-manage" @onChange="(v) => onDelete()" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="jobOrderForm" #default="{ invalid }">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col cols="12" :md="openSection ? '9' : '12'">
                      <b-row>
                        <b-col>
                          <h2>
                            Service Report Information -
                            <strong>
                              {{ formData.preview_job_status }}
                              ({{ formData.job_name }})
                            </strong>
                          </h2>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Ticket No."
                            label-for="v-ticket-no"
                            disabled
                          >
                            <b-form-input
                              v-model="formData.ticket_no"
                              id="v-ticket-no"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5">
                          <b-form-group label="Company" label-for="v-company">
                            <b-form-input
                              v-model="formData.company_name"
                              id="v-company"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                          <b-form-group
                            label="Contract No."
                            label-for="v-ticket-no"
                          >
                            <b-form-input
                              v-model="formData.contract_refnum"
                              id="v-ticket-no"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                          <b-form-group label="Po No." label-for="v-company">
                            <b-form-input
                              v-model="formData.po_no"
                              id="v-company"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Job Name"
                            label-for="v-ticket-no"
                          >
                            <b-form-input
                              v-model="formData.job_name"
                              disabled
                              id="v-ticket-no"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group label="Job Type" label-for="v-company">
                            <b-form-input
                              v-model="formData.job_type"
                              id="v-company"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Job Status"
                            label-for="v-company"
                          >
                            <b-form-input
                              v-model="formData.preview_job_status"
                              id="v-company"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Recipients"
                            label-for="v-company"
                          >
                            <b-form-input
                              v-model="formData.email"
                              id="v-company"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Customer Name"
                            label-for="v-customer_name"
                          >
                            <b-form-input
                              v-model="formData.customer_name"
                              id="v-customer_name"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group label-for="v-email">
                            <template v-slot:label>
                              Email <span class="text-danger">*</span>
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="Email"
                              rules="required|email"
                            >
                              <b-form-input
                                maxlength="60"
                                v-model="formData.customer_email"
                                :state="errors.length > 0 ? false : null"
                                type="email"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Customer Contact"
                            label-for="v-customer_contact"
                          >
                            <b-form-input
                              v-model="formData.customer_contact"
                              id="v-customer_contact"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Report Date"
                            label-for="v-report_date-date"
                          >
                            <b-form-input
                              disabled
                              v-model="formData.preview_report_date"
                              id="v-report_date-date"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Chargeable Amount"
                            label-for="v-chargeable_amount"
                          >
                            <b-form-input
                              v-model="formData.chargeable_amount"
                              id="v-chargeable_amount"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="New Chargeable Amount"
                            label-for="v-post_amount"
                          >
                            <b-form-input
                              v-model="formData.post_amount"
                              id="v-post_amount"
                              class="text-right"
                              @change="convertToDecimal"
                              @input="incorrectDecimal"
                              @keypress="isNumber"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="New Chargeable Amount Remarks"
                            label-for="v-post_amount_remarks"
                          >
                            <b-form-input
                              v-model="formData.post_amount_remarks"
                              id="v-post_amount_remarks"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Chargeable Type"
                            label-for="v-chargeable_type"
                          >
                            <v-select
                              id="v-chargeable_type"
                              v-model="formData.chargeable_type"
                              :options="chargeableTypes"
                              :reduce="
                                (chargeableTypes) => chargeableTypes.value
                              "
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Chargeable Hours"
                            label-for="v-chargeable_hours"
                          >
                            <b-form-input
                              id="v-chargeable_hours"
                              v-model.number="formData.chargeable_hours"
                              type="number"
                              class="form-control"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Job Started"
                            label-for="v-job_etd"
                          >
                            <b-form-input
                              id="v-job_eta"
                              v-model="formData.preview_job_eta"
                              type="datetime-local"
                              class="form-control"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group label="Job Ended" label-for="v-job_eta">
                            <b-form-input
                              id="v-job_eta-date"
                              v-model="formData.preview_job_etd"
                              type="datetime-local"
                              :class="{ 'border-danger': errJobDateRange }"
                              class="form-control"
                              @change="jobDateTimeValidation"
                            />

                            <small class="text-danger" v-if="errJobDateRange">
                              <strong>Job Ended</strong> must not be less than
                              <strong>Job Started</strong>
                            </small>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" md="6">
                          <b-form-group
                            label="Report Start"
                            label-for="v-report_start"
                          >
                            <b-form-input
                              v-model="formData.preview_report_start"
                              id="v-report_start"
                              type="datetime-local"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                          <b-form-group
                            label="Report End"
                            label-for="v-report_end"
                          >
                            <b-form-input
                              v-model="formData.preview_report_end"
                              id="v-report_end"
                              :class="{ 'border-danger': errReportDateRange }"
                              type="datetime-local"
                              @change="reportDateTimeValidation"
                            />

                            <small
                              class="text-danger"
                              v-if="errReportDateRange"
                            >
                              <strong>Report End</strong> must not be less than
                              <strong>Report Start</strong>
                            </small>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Problem Description"
                            label-for="v-problem_description"
                          >
                            <b-form-textarea
                              id="v-problem_description"
                              v-model="formData.problem_description"
                              rows="4"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Services Performed"
                            label-for="v-services_performed"
                          >
                            <quill-editor
                              ref="quillEditorRefServicesPerformed"
                              v-model="content"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Job Description"
                            label-for="v-job_description"
                          >
                            <quill-editor
                              disabled
                              ref="quillEditorRef"
                              v-model="formData.job_instructions"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Assignment Information</h2>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Primary Technician"
                            label-for="v-sales-person"
                          >
                            <v-select
                              v-model.number="formData.job_assigned_to_1"
                              :options="employeeItems"
                              :reduce="(employeeItems) => employeeItems.value"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Secondary Technician"
                            label-for="v-sales-person"
                          >
                            <v-select
                              v-model.number="formData.job_assigned_to_2"
                              :options="employeeItems"
                              :reduce="(employeeItems) => +employeeItems.value"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="6">
                          <h2>Job Site</h2>
                        </b-col>

                        <b-col cols="6" class="text-right">
                          <feather-icon
                            @click="isHidingForm = !isHidingForm"
                            class="pin isDisplaying"
                            :class="{
                              arrow_down: isHidingForm,
                              arrow_up: !isHidingForm,
                            }"
                            icon="ChevronUpIcon"
                            size="36"
                          />
                        </b-col>
                      </b-row>

                      <span v-if="!isHidingForm">
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="Site/Branch/Outlet Name"
                              label-for="v-site_id"
                            >
                              <b-form-input
                                disabled
                                v-model="formData.site_id"
                                id="v-site_id"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="2">
                            <b-form-group
                              label="Service Type"
                              label-for="v-service_type"
                            >
                              <v-select
                                disabled
                                id="v-service_type"
                                v-model="formData.contract_type"
                                :options="[
                                  'project',
                                  'installation',
                                  'maintenance',
                                  'chargeable',
                                ]"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="2">
                            <b-form-group
                              label="Service Location"
                              label-for="v-service_location"
                            >
                              <b-form-input
                                disabled
                                id="v-service_location"
                                v-model="serviceLocation"
                                class=""
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Building/Block/House No. and Street Name"
                              label-for="v-addr1"
                            >
                              <b-form-input
                                disabled
                                v-model="formData.addr1"
                                id="v-addr1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Unit No. and Building Name"
                              label-for="v-addr2"
                            >
                              <b-form-input
                                disabled
                                v-model="formData.addr2"
                                id="v-addr2"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group label="Country" label-for="v-country">
                              <b-form-input
                                disabled
                                v-model="formData.country"
                                id="v-country"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Postal Code"
                              label-for="v-postal"
                            >
                              <b-form-input
                                disabled
                                v-model="formData.postal"
                                id="v-postal"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <ModalMap
                          :showMap="isShowingMap"
                          @onChangePlace="(v) => getPlace(v)"
                        />
                      </span>

                      <b-row>
                        <b-col cols="6">
                          <h2>Contact Information</h2>
                        </b-col>

                        <b-col cols="6" class="text-right">
                          <feather-icon
                            @click="isHidingFormContact = !isHidingFormContact"
                            class="pin isDisplaying"
                            :class="{
                              arrow_down: isHidingFormContact,
                              arrow_up: !isHidingFormContact,
                            }"
                            icon="ChevronUpIcon"
                            size="36"
                          />
                        </b-col>
                      </b-row>

                      <span v-if="!isHidingFormContact">
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Contact Person"
                              label-for="v-poc"
                            >
                              <b-form-input
                                disabled
                                v-model="formData.poc"
                                id="v-poc"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group label="Mobile" label-for="v-mobile">
                              <b-form-input
                                disabled
                                v-model="formData.mobile"
                                id="v-mobile"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </span>

                      <b-row>
                        <b-col>
                          <h2>Propose spares needed</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobOrderSpares :data-source="dataSourceSpares" />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Products for servicing</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobOrderDetails :data-source="dataSourceDetails" />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Images</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobOrderImages :data-source="dataSourceImages" />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Customer checklist(s)</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobOrderCustomerChecklists
                              :data-source="dataSourceCustomerChecklists"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Technician checklist(s)</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobOrderTechnicianChecklists
                              :data-source="dataSourceTechnicianChecklists"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="6">
                          <h2>Other Information</h2>
                        </b-col>

                        <b-col cols="6" class="text-right">
                          <feather-icon
                            @click="isHidingFormRemarks = !isHidingFormRemarks"
                            class="pin isDisplaying"
                            :class="{
                              arrow_down: isHidingFormRemarks,
                              arrow_up: !isHidingFormRemarks,
                            }"
                            icon="ChevronUpIcon"
                            size="36"
                          />
                        </b-col>
                      </b-row>

                      <span v-if="!isHidingFormRemarks"
                        ><b-row>
                          <b-col cols="12">
                            <b-form-group label="GST" label-for="v-gst">
                              <v-select
                                id="v-gst"
                                v-model="formData.gst"
                                :options="gsts"
                                :reduce="(gsts) => gsts.value"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              label="Instructions/Remarks"
                              label-for="v-remarks"
                            >
                              <b-form-textarea
                                v-model="formData.remarks"
                                id="v-remarks"
                                rows="4"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12" md="4">
                            <b-form-group
                              label="Payment Mode"
                              label-for="v-payment_mode"
                            >
                              <v-select
                                id="v-payment_mode"
                                v-model="formData.payment_mode"
                                :options="paymentModes"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="4">
                            <b-form-group
                              label="Cheque No."
                              label-for="v-cheque_no"
                            >
                              <b-form-input
                                v-model="formData.cheque_no"
                                id="v-cheque_no"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="4">
                            <b-form-group
                              label="Follow-up Type"
                              label-for="v-job_followup_type"
                            >
                              <v-select
                                id="v-job_followup_type"
                                v-model="formData.job_followup_type"
                                :options="jobFollowUpTypes"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              label="Follow-up Description"
                              label-for="v-job_followup_description"
                            >
                              <b-form-textarea
                                v-model="formData.job_followup_description"
                                id="v-job_followup_description"
                                rows="4"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              label="Customer Rating"
                              label-for="v-customer_rating"
                            >
                              <b-form-rating
                                no-border
                                inline
                                variant="warning"
                                v-model="formData.customer_rating"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group>
                              <b-card>
                                <b-row>
                                  <b-col>
                                    <b-form-group>
                                      <b-img
                                        style="width: 100%; height: 450px"
                                        :src="filePreviewCustomerSignature"
                                      />
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-form-group label="Customer Signature">
                                      <b-form-file
                                        v-model="fileUploadCustomerSignature"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..."
                                        id="fileUploadCustomerSignature"
                                      />
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                              </b-card>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group>
                              <b-card>
                                <b-row>
                                  <b-col>
                                    <b-form-group>
                                      <b-img
                                        style="width: 100%; height: 450px"
                                        :src="filePreviewCompanyStamp"
                                      />
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-form-group label="Company Stamp">
                                      <b-form-file
                                        v-model="fileUploadCompanyStamp"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..."
                                        id="fileUploadCompanyStamp"
                                      />
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                              </b-card>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </span>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                        @click="returnToPage"
                      >
                        Back
                      </b-button>
                      <b-button
                        v-if="hasGeneratedPDF"
                        :disabled="invalid || isLoading"
                        @click="serviceReport()"
                        class="mr-1"
                        type="button"
                        variant="info"
                      >
                        Generate Service Report
                      </b-button>
                      <b-button
                        :disabled="invalid || isLoading"
                        v-b-modal.tab-information
                        type="button"
                        variant="success"
                      >
                        Submit
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <span v-if="$route.query.id">
                <b-tab title="Logs">
                  <Logs ref="logsRef" module="joborder" />
                </b-tab>
                <b-tab title="Comments">
                  <Comments ref="commentsRef" module="joborder" />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import jobOrderApi from '@api/joborder_headers';
import employeesApi from '@api/employees';
import usersApi from '@api/users';
import gstsApi from '@api/gsts';
import { required, email } from '@validations';
// @todo change from utils classes to global-function
import { nowToLocaleDateString, UtilsDate } from '@/utils/classes/dates';
import { queryParameters } from '@/schema';
import JobOrderDetails from './components/JobOrderDetails';
import JobOrderImages from './components/JobOrderImages';
import JobOrderSpares from './components/JobOrderSpares';
import JobOrderCustomerChecklists from './components/JobOrderCustomerChecklists';
import JobOrderTechnicianChecklists from './components/JobOrderTechnicianChecklists';
import JobOrderLogs from './components/JobOrderLogs';
import JobOrderComments from './components/JobOrderComments';

export default {
  name: 'JobOrderManage',
  components: {
    JobOrderDetails,
    JobOrderImages,
    JobOrderSpares,
    JobOrderCustomerChecklists,
    JobOrderTechnicianChecklists,
    JobOrderLogs,
    JobOrderComments,
  },
  data: () => ({
    required,
    email,
    success: false,
    openSection: false,
    fileUploadCustomerSignature: null,
    fileUploadCompanyStamp: null,
    errors: {},
    activeTab: 0,
    isLoading: false,
    isShowingMap: false,
    isHidingForm: false,
    isHidingFormContact: false,
    isHidingFormRemarks: false,
    dataSourceDetails: [],
    dataSourceImages: [],
    dataSourceSpares: [],
    dataSourceCustomerChecklists: [],
    dataSourceTechnicianChecklists: [],
    content: '',
    chargeableTypes: [
      { label: 'Hours', value: 'H' },
      { label: 'Token', value: 'T' },
    ],
    jobWorkModes: [
      { label: 'Onsite', value: 'O' },
      { label: 'Remote', value: 'R' },
    ],
    paymentModes: ['Cash', 'Check'],
    jobFollowUpTypes: [
      'Job Completed',
      'Job Completed with Follow Up',
      'Cancelled',
    ],
    formData: {
      id: null,
      company_id: null,
      company_name: null,
      job_id: null,
      refnum: null,
      job_type: null,
      job_status: null,
      site_id: null,
      problem_description: null,
      report_date: null,
      urgency_level: null,
      poc: null,
      mobile: null,
      tel: null,
      email: null,
      designation: null,
      ticket_no: null,
      addr1: null,
      addr2: null,
      postal: null,
      country: 'Singapore',
      remarks: null,
      fax: null,
      job_assigned_to_1: null,
      customer_signature: null,
      company_stamp: null,
      contract_refnum: null,
      po_no: null,
      customer_contact: null,
      customer_name: null,
      customer_email: null,
      chargeable_hours: null,
      chargeable_type: 'H',
      services_performed: null,

      preview_report_date: nowToLocaleDateString,
    },
    employeeItems: [],
    gsts: [],
    filePreviewCustomerSignature: null,
    filePreviewCompanyStamp: null,
    fileB64CompanyStamp: null,
    fileNameCustomerSignature: null,
    fileNameCompanyStamp: null,
    errJobDateRange: false,
    errReportDateRange: false,
  }),
  watch: {
    activeTab(index) {
      if (index === 1) {
        this.$refs.logsRef.loadLogs();
      }

      if (index === 2) {
        this.$refs.commentsRef.loadTimelineHeader();
      }
    },
    section(v) {
      this.openSection = v;
    },
    fileUploadCustomerSignature(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileNameCustomerSignature = file.name;
          this.filePreviewCustomerSignature = reader.result;
        };
      }
    },
    fileUploadCompanyStamp(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileNameCompanyStamp = file.name;
          this.filePreviewCompanyStamp = reader.result;
          this.fileB64CompanyStamp = reader.result;
        };
      }
    },
  },
  computed: {
    serviceLocation: {
      get() {
        const { service_location } = this.formData;

        return service_location === 'O' ? 'On-Site' : 'Remote';
      },
    },
    hasGeneratedPDF: {
      get() {
        const { pdf } = this.formData;

        return pdf === '';
      },
    },
  },
  mounted() {
    // this.loadEmployees();
    this.loadGSTS();
    this.loadUsers();
    if (+this.$route.query.id) {
      this.loadJobRequest();
    }
  },
  methods: {
    loadGSTS() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      gstsApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const { name, description, percent } = item;

            items.push({
              label: `${description} - ${percent}%`,
              value: name,
              item,
            });
          }

          this.gsts = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadEmployees() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      employeesApi
        .list(params)
        .then(({ data }) => {
          this.employeeItems = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUsers() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 10000 },
        status: queryParameters.status,
      };

      usersApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            let fullName = item.first_name;
            if (item.last_name) {
              fullName += ` ${item.last_name}`;
            }

            items.push({ label: fullName, value: +item.id, item });
          }

          this.employeeItems = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadJobRequest() {
      jobOrderApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data) {
            const {
              report_date,
              job_eta,
              job_etd,
              job_status,
              job_id,
              report_start,
              report_end,
              customer_signature,
              company_stamp,
              post_amount,
              chargeable_amount,
            } = data;

            const displayToLocale = (date) => {
              if (date) {
                return this.inputDateTimeFromUnix(date);
              }
            };

            const getDetails = (details) => {
              if (details && details.data) {
                return details.data;
              }

              return [];
            };

            data.preview_job_status = this.getJobStatus(job_status);

            data.preview_report_date = displayToLocale(report_date);
            data.preview_job_eta = displayToLocale(job_eta);
            data.preview_job_etd = displayToLocale(job_etd);
            data.preview_report_start = displayToLocale(report_start);
            data.preview_report_end = displayToLocale(report_end);

            if (job_id && data.joborder_job.data) {
              data.job_name = data.joborder_job.data.name;
            }

            const { joborder_jobrequest_header: jh } = data;
            if (jh && jh.data) {
              const site = jh.data;
              data.addr1 = site.addr1;
              data.addr2 = site.addr2;
              data.country = site.country;
              data.postal = site.postal;
            }

            this.dataSourceDetails = getDetails(data.joborder_details);
            this.dataSourceImages = getDetails(data.joborder_images);
            this.dataSourceSpares = getDetails(data.joborder_spares);
            this.dataSourceCustomerChecklists = getDetails(
              data.joborder_customer_checklists
            );
            this.dataSourceTechnicianChecklists = getDetails(
              data.joborder_technician_checklists
            );

            const contractType = data.contract_type.split(',');

            if (contractType.includes('project')) {
              data.ctype_project = true;
            }

            if (contractType.includes('maintenance')) {
              data.ctype_maintenance = true;
            }

            if (contractType.includes('chargeable')) {
              data.ctype_chargeable = true;
            }

            if (contractType.includes('installation')) {
              data.ctype_installation = true;
            }

            if (customer_signature) {
              this.filePreviewCustomerSignature = customer_signature;
            }

            if (company_stamp) {
              const url = process.env.VUE_APP_ROOT_STATIC_URL;
              this.filePreviewCompanyStamp = `${url}/joborder/${data.id}/${company_stamp}`;
            }

            const { quill } = this.$refs.quillEditorRefServicesPerformed;
            if (quill) {
              quill.root.innerHTML = data.services_performed;
            }

            data.post_amount = post_amount.toFixed(2);
            data.chargeable_amount = chargeable_amount.toFixed(2);

            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      this.cleanFormData();
      this.isLoading = true;
      const success = () => {
        window.scrollTo(0, 0);
        this.success = true;
        this.toastConfig();
        this.loadJobRequest();
      };

      const error = (err) => {
        window.scrollTo(0, 0);
        this.toastConfig('', 'error');
        this.success = false;
        this.errors = err;
      };

      let app = null;

      const replace = (data) => {
        if (data) {
          return Number(data.replace(/[^0-9.-]+/g, ''));
        }
      };

      const { post_amount: pa, chargeable_amount: ca } = this.formData;

      this.formData.post_amount = replace(pa);
      this.formData.chargeable_amount = replace(ca);

      if (!this.formData.id) {
        app = jobOrderApi.add(this.formData);
      } else {
        app = jobOrderApi.update(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.isLoading = false;
            const url = `/pages/services-tools/service-report/view?id=${data.id}`;
            this.$router.push(url);
            success();
          })
          .catch((err) => {
            error(err);
          })
          .finally(() => {
            //
          });
      }
    },
    returnToPage() {
      this.$router.push(`/pages/services-tools/service-report`);
    },
    getPlace(place) {
      //
    },
    serviceReport() {
      this.cleanFormData();
      this.isLoading = true;
      const success = () => {
        window.scrollTo(0, 0);
        this.success = true;
        this.toastConfig();
        this.loadJobRequest();
      };

      const error = (err) => {
        window.scrollTo(0, 0);
        this.toastConfig('', 'error');
        this.success = false;
        this.errors = err;
      };

      const replace = (data) => {
        if (data) {
          return Number(data.replace(/[^0-9.-]+/g, ''));
        }
      };

      const { post_amount: pa, chargeable_amount: ca } = this.formData;

      this.formData.post_amount = replace(pa);
      this.formData.chargeable_amount = replace(ca);

      jobOrderApi
        .serviceReport(this.formData)
        .then(({ data }) => {
          success();
          this.isLoading = false;
          const url = `/pages/services-tools/service-report/view?id=${data.id}`;
          this.$router.push(url);
        })
        .catch((err) => {
          error(err);
        })
        .finally(() => {
          //
        });
    },
    cleanFormData() {
      const toUnix = (date) => {
        if (!date) {
          return new UtilsDate().dateToUnix();
        }

        return this.inputDateTimeToUnix(date);
      };

      const data = this.formData;
      const signature = this.filePreviewCustomerSignature;
      data.customer_signature = signature ? signature : '';
      data.customer_signature_date = signature ? toUnix(new Date()) : null;
      data.base64 = this.fileB64CompanyStamp;
      data.company_stamp = this.fileNameCompanyStamp || data.company_stamp;
      data.services_performed = this.content;

      data.job_eta = toUnix(data.preview_job_eta);
      data.job_etd = toUnix(data.preview_job_etd);
      data.report_start = toUnix(data.preview_report_start);
      data.report_end = toUnix(data.preview_report_end);

      let contract_type = [];

      if (data.ctype_project) {
        contract_type.push('project');
      }

      if (data.ctype_maintenance) {
        contract_type.push('maintenance');
      }

      if (data.ctype_chargeable) {
        contract_type.push('chargeable');
      }

      if (data.ctype_installation) {
        contract_type.push('installation');
      }

      data.contract_type = contract_type.join(',');

      const getDetails = (dataSource, opt = { addOn: false }) => {
        const details = [];
        for (let i = 0; i < dataSource.length; i++) {
          const detail = dataSource[i];
          if (opt.addOn) {
            if (typeof detail.id !== 'number') {
              detail.reference_id = detail.id;
            }
          }

          details.push(detail);
        }

        return details;
      };

      data.details = getDetails(this.dataSourceDetails, { addOn: true });
      data.images = getDetails(this.dataSourceImages, { addOn: true });
      data.spares = getDetails(this.dataSourceSpares);
      data.customer_checklists = getDetails(this.dataSourceCustomerChecklists);
      data.technician_lists = getDetails(this.dataSourceTechnicianChecklists);
    },
    convertToDecimal() {
      const { post_amount } = this.formData;

      if (
        post_amount != '' &&
        this.correctMoneyFormat(this.formData.post_amount)
      ) {
        const toDecimal = this.moneyFormat(this.formData.post_amount).replace(
          '$',
          ''
        );

        const valueIsNan = isNaN(toDecimal.replaceAll(',', ''));
        this.formData.post_amount = valueIsNan ? '0.00' : toDecimal;

        return;
      }

      this.formData.post_amount = 0;
    },
    incorrectDecimal() {
      let { post_amount } = this.formData;
      this.correctDecimalFormat = this.correctMoneyFormat(post_amount);
    },
    isNumber(event) {
      if (isNaN(event.key) && event.key !== '.' && event.key !== 'Backspace') {
        event.preventDefault();
      }

      if (event.code === 'Space') {
        event.preventDefault();
      }
    },
    jobDateTimeValidation() {
      const etd = new Date(this.formData.preview_job_etd);
      const eta = new Date(this.formData.preview_job_eta);

      this.isLoading = eta > etd;
      this.errJobDateRange = eta > etd;
    },
    reportDateTimeValidation() {
      const report_end = new Date(this.formData.preview_report_end);
      const report_start = new Date(this.formData.preview_report_start);

      this.isLoading = report_start > report_end;
      this.errReportDateRange = report_start > report_end;
    },
  },
};
</script>

<style lang="scss">
.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>
